import React, { useEffect } from 'react';

import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FadeInFromTop } from '@/components/AnimatedContainers';
import { SectionContent } from '@/components/Layout';
import LogoWithText from '@/components/Logo/LogoWithText';
import Page from '@/components/Pages/Page';
import { useAuth } from '@/context/AuthContext';
import { Button, H1, Link } from '@/elements';

export default function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const router = useRouter();
  const { isAuthenticated, signIn, signInError, isSigningIn, self } = useAuth();

  const onSubmit = async (values) => {
    if (values.email && values.password) {
      signIn(values.email, values.password);
    }
  };

  // redirect if signed in
  useEffect(() => {
    if (isAuthenticated && self && !isSigningIn) {
      router.push('/');
    }
  }, [isAuthenticated, isSigningIn, self, router]);

  return (
    <Page
      withHeader={false}
      height='100%'
      position='relative'
      background='brand.purple'
    >
      <SectionContent>
        <Center height='211px' zIndex={0} position={'relative'}>
          <Box
            backgroundImage={'/images/login-header.png'}
            position={'absolute'}
            top={0}
            width={'100%'}
            margin={'0 auto'}
            bottom={0}
            maxW='500px'
          ></Box>
          <FadeInFromTop zIndex={1}>
            <LogoWithText />
          </FadeInFromTop>
        </Center>

        <Center
          position='relative'
          zIndex={1}
          flexDir='column'
          bg='brand.white'
          borderRadius='24px'
          maxW='500px'
          margin='0 auto'
          p='md'
        >
          <Center flexDir='column' mb='xl' mt='72px'>
            <H1>
              <FormattedMessage
                id='views.auth.signin.title'
                defaultMessage='Sign In'
              />
            </H1>
          </Center>
          <Box maxW='400px' w='100%'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={!!errors.email} mb='md'>
                <Input
                  {...register('email', {
                    required: '* This field is required',
                  })}
                  id='email'
                  placeholder='Your email'
                  type='email'
                />
                {errors.email && (
                  <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                isInvalid={!!errors.password}
                mb='xl'
                display={'flex'}
                flexDirection={'column'}
              >
                <Input
                  {...register('password', {
                    required: '* This field is required',
                  })}
                  id='password'
                  placeholder='Password'
                  type='password'
                />
                {errors.password && (
                  <FormErrorMessage>{errors.password.message}</FormErrorMessage>
                )}
                <Link
                  fontSize='13px'
                  lineHeight='18px'
                  alignSelf={'flex-end'}
                  marginTop={1}
                  href={'/auth/forgot-password'}
                >
                  <FormattedMessage
                    id='views.auth.signin.link.forgotPassword'
                    defaultMessage={'Forgot password'}
                  />
                </Link>
              </FormControl>

              {signInError && (
                <Text color='red' mt='2'>
                  {signInError}
                </Text>
              )}

              <Center mb={12} mt='md'>
                <Button isLoading={isSigningIn} minWidth={200} type='submit'>
                  <FormattedMessage
                    id='views.auth.signin.button.signin'
                    defaultMessage={'Sign In'}
                  />
                </Button>
              </Center>

              <Center flexDir='column'>
                <Text mb='md' variant='labelMedium' fontWeight={500}>
                  <FormattedMessage
                    id='views.auth.signin.dontHaveAccount'
                    defaultMessage={`Don't have an account?`}
                  />
                </Text>
                <Link href='/auth/register'>
                  <Text variant='uppercase'>
                    <FormattedMessage
                      id='views.auth.signin.link.signup'
                      defaultMessage={'Sign Up'}
                    />
                  </Text>
                </Link>
              </Center>
            </form>
          </Box>
        </Center>
      </SectionContent>
    </Page>
  );
}
